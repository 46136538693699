import { FC } from 'react';
import Link from 'next/link';
import styles from './ScrollCardItem.module.scss';

const ScrollCardAdevertisment: FC = () => {
  const CARD_LINK = '/redirect_to_self_pub';

  return (
    <div className={`${styles.scrollCard} ${styles.scrollCard_advert}`}>
      <div className={styles.scrollCard__info}>
        <Link prefetch={false} className={styles.scrollCard__imgWrap} href={CARD_LINK}>
          <img loading="lazy" className={styles.scrollCard__img} src="/banners/selfpub-card.png" width="114" height="162" alt="card-advert" />
        </Link>
        <div className={styles.scrollCard__desc}>
          <Link prefetch={false} className={styles.scrollCard__title} href={CARD_LINK}>
            Будущий автор
          </Link>
          <Link prefetch={false} className={styles.scrollCard__author} href={CARD_LINK}>
            Литрес Самиздат
          </Link>
          <div className={styles.scrollCard__rating}>
            <span className={styles.scrollCard__ratingNumber}>5</span>
          </div>
        </div>
      </div>
      <div className={styles.scrollCard__btn}>Стать автором</div>
    </div>
  );
};

export default ScrollCardAdevertisment;
