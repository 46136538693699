import { FC } from 'react';
import Layout from '@/components/layout/Layout';
import SectionTitle from '@/components/ui/section-title/SectionTitle';
import SectionTitleMenu from '@/components/ui/section-title-menu/SectionTitleMenu';
import BookCardsHiddenList from '@/components/ui/book-cards-hidden-list/BookCardsHiddenList';
import ScrollCardsList from '@/components/ui/scroll-cards-list/ScrollCardsList';
import ScrollCardItem from '@/components/ui/scroll-card-item/ScrollCardItem';
import ScrollAuthorCardItem from '@/components/ui/scroll-author-card-item/ScrollAuthorCardItem';
import ScrollExternalCardItem from '@/components/ui/scroll-external-card-item/ScrollExternalCardItem';
import ScrollReviewItem from '@/components/ui/scroll-review-item/ScrollReviewItem';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { ITopAuthors } from '@/interfaces/topAuthors.interface';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';
import { ILastReviews } from '@/interfaces/lastReviews';
import { IGenresData } from '@/interfaces/genres.interface';
import ScrollCardAdevertisment from '@/components/ui/scroll-card-item/ScrollCardItemAdvertisment';

interface IHomePage {
  popularBooks: IBookPage;
  newBooks: IBookPage;
  topAuthors: ITopAuthors;
  reviews: ILastReviews;
  advertisingBlock: IAdvertisingBlock;
  menuGenres: IGenresData[];
}

const Home: FC<IHomePage> = ({ popularBooks, newBooks, topAuthors, reviews, advertisingBlock, menuGenres }) => {
  //seo block
  const SEO_TITLE = 'Бесплатная электронная библиотека – скачать книги или читать онлайн бесплатно.';
  const SEO_DESCRIPTION = 'Мы предлагаем большой выбор 📚 электронных и аудиокниг. ✔️Читайте на любом устройстве. ✔️Откройте мир знаний.';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref="/" menuGenres={menuGenres} menuNew={newBooks}>
      <SectionTitleMenu name={'Популярные книги'} />
      <BookCardsHiddenList books={popularBooks.data} extraClassName="bookCardsList_mb" />

      <SectionTitle name={'Новинки'} />
      <ScrollCardsList>
        <ScrollCardAdevertisment />
        {newBooks.data.length ? newBooks.data.map((book: IBooksPageData) => <ScrollCardItem key={book.id} id={book.id} rating={book.rating} author={book.author} book={book.book} />) : <div>Книги не найдены</div>}
      </ScrollCardsList>

      <SectionTitle name={'Популярные авторы'} />
      <ScrollCardsList>{topAuthors.length ? topAuthors.map((author) => <ScrollAuthorCardItem key={author.id} author={author} />) : <div>Авторы не найдены</div>}</ScrollCardsList>

      <SectionTitle name={'Поcледние отзывы'} />
      <ScrollCardsList>{reviews.length ? reviews.map((review) => <ScrollReviewItem key={review.id} review={review} />) : <div>Отзывы не найдены</div>}</ScrollCardsList>

      <SectionTitle name={'Новинки нашего партнера'} />
      <ScrollCardsList>{advertisingBlock.length ? advertisingBlock.map((book) => <ScrollExternalCardItem key={book.id} book={book} />) : <div>Книги не найдены</div>}</ScrollCardsList>
    </Layout>
  );
};

export default Home;
